import React from "react";
import HeroSection from "~sections/Common/Hero";
import AboutSection from "~sections/Careers/About";
import JobSection from "~sections/Careers/Job";
import FooterSection from "~sections/Common/Footer";
import { PageWrapper } from "~components/Core";
import career from "~data-yaml/career.yml"
import HeroBg from "~image/heroimages/career/hero_bg.jpg"
import heroOverlay from "~image/heroimages/career/overlay.png"

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

const Career = () => {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection 
          title={career.hero.title}
          subtitle={career.hero.subtitle}
          text={career.hero.text}
          HeroBg={HeroBg}
          Overlay={heroOverlay}
        />
        <AboutSection/>
        <JobSection/>
        <FooterSection/>
    </PageWrapper>
  )
}

export default Career