import React,{useState} from 'react'
import loadable from '@loadable/component'
// https://github.com/gregberge/loadable-components

const ModalVideoComponent = loadable(() => import('react-modal-video'))

const Video = ({id,className,children}) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <React.Fragment>
      <ModalVideoComponent channel='youtube' autoplay isOpen={isOpen} videoId={id} onClose={() => setOpen(false)} />
     <button className={`btn-reset${className?" "+className:""}`} onClick={()=> setOpen(true)}>{children}</button>
    </React.Fragment>
  )
}

export default Video;