import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import About from './style'
import SectionTitle from './Components/SectionTitle'
import Video from '~components/VideoModal'
import Data from "~data-yaml/career.yml"

const AboutSection = () => {
  return (
    <About>
      <Container>
        {/* about-us Content */}
        <Row className="align-items-center justify-content-center">
          <Col col="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-11 order-2 order-lg-1">
            <About.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
              <SectionTitle
                title={Data.about.title}
                text={Data.about.text}
                titleProps={{ mb: "20px" }}
                subTitleProps={{ mb: "25px" }}
              />
            </About.Content>
          </Col>
          <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-11 order-1 order-lg-2">
            <About.Image>
              <Img className="w-100" src="../../../assets/image/mixed/career-video-bg.png" alt="career" layout="fullWidth" placeholder="blurred" />
              <Video id="LWZ7iytIA6k" className="video-btn sonar-emitter"> <i className="fa fa-play" /> </Video>
            </About.Image>
          </Col>
        </Row>
        {/*/ .about-us Content */}
      </Container>
    </About>

  )
}
export default AboutSection